import Assets from '@/Assets';
import HeaderlessLayout from '@/Layouts/HeaderlessLayout';
import Head from '@/Components/Head';

export default function NotFound() {
    return (
        <HeaderlessLayout>
            <Head title="Not Found" />
            <div className="fixed bottom-0 top-0 z-[-1] w-full bg-[#E6007A]" />
            <div className="flex w-full flex-col items-center p-[26px] sm:p-4">
                <Assets.svg.polkaid_white_logo />
                <div className="mt-[152px] flex w-[22%] flex-col gap-[88px] lg:w-full lg:gap-[88px] md:w-full md:gap-[66px] sm:gap-11">
                    <div className="flex flex-col items-center gap-9">
                        <div className="flex flex-col items-center self-stretch">
                            <h1 className="font-dmsans2 text-[220px] font-bold text-white-a700 lg:text-[220px] md:text-[48px]">
                                404
                            </h1>
                            <h2 className="font-dmsans2 relative mt-[38px] text-[30px] font-bold text-white-a700 lg:text-[25px] md:text-[28px] sm:text-[26px]">
                                Page not found
                            </h2>
                        </div>
                        <p className="font-dmsans2 text-center leading-[26px] text-white-a700">
                            <>
                                The page you are looking for doesn't exists or
                                an other error occurred.
                            </>
                        </p>
                    </div>
                    <button
                        onClick={() => history.back()}
                        className="mx-[52px] flex h-[60px] cursor-pointer flex-row items-center justify-center self-stretch whitespace-nowrap rounded-lg bg-white-a700 px-[34px] text-center text-[20px] font-bold text-black-900 lg:text-[17px]"
                    >
                        Go back
                    </button>
                </div>
            </div>
        </HeaderlessLayout>
    );
}
